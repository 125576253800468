import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Send email "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[(_vm.edit)?_c('div',[_c(VTextarea,{attrs:{"outlined":"","auto-grow":""},model:{value:(_vm.emailData.body),callback:function ($$v) {_vm.$set(_vm.emailData, "body", $$v)},expression:"emailData.body"}}),_c(VBtn,{staticClass:"text-none",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){return _vm.send()}}},[_vm._v("Send "),_c(VIcon,{staticClass:"ml-2"},[_vm._v("mdi-send")])],1),_c(VBtn,{staticClass:"text-none mt-2",attrs:{"block":"","color":"grey","text":"","large":""},on:{"click":function($event){_vm.edit = false}}},[_vm._v("Cancel")])],1):_c('div',{staticClass:"list-container"},[(!_vm.templates)?_c('div',{staticClass:"text-center"},[_c('BaseProgressCircular')],1):_c('div',_vm._l((_vm.templates),function(template){return _c('div',{key:template.id,staticClass:"email-container d-flex align-center",on:{"click":function($event){return _vm.sendEmail(template.id)}}},[(template.icon)?_c('div',[_c(VIcon,{staticClass:"mr-2",attrs:{"color":template.color || 'primary'}},[_vm._v(_vm._s(template.icon))])],1):_vm._e(),_c('div',{staticClass:"email-content"},[_c('div',{staticClass:"email-title"},[_vm._v(_vm._s(template.subject || 'Plain email'))])])])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }