<template>
  <v-card class="mx-auto">
    <v-card-title>
      Send email
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-container>
      <div v-if="edit">
        <v-textarea outlined auto-grow v-model="emailData.body"></v-textarea>
        <v-btn class="text-none" block color="primary" large @click="send()"
          >Send <v-icon class="ml-2">mdi-send</v-icon></v-btn
        >
        <v-btn class="text-none mt-2" block color="grey" text large @click="edit = false"
          >Cancel</v-btn
        >
      </div>
      <div class="list-container" v-else>
        <div v-if="!templates" class="text-center">
          <BaseProgressCircular />
        </div>
        <div v-else>
          <div
            @click="sendEmail(template.id)"
            class="email-container d-flex align-center"
            v-for="template in templates"
            :key="template.id"
          >
            <div v-if="template.icon">
              <v-icon :color="template.color || 'primary'" class="mr-2">{{ template.icon }}</v-icon>
            </div>
            <div class="email-content">
              <div class="email-title">{{ template.subject || 'Plain email' }}</div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Contact from '../../services/contact';
import Land from '../../services/land';
import Parcel from '../../services/parcel';
import { fullName, parseJson, randomNumber, sort } from '../../utils';
import { getStreetAddress } from '../../utils/parcel';
import moment from 'moment';
import { CMS } from '../../services/cms';
import Mustache from 'mustache';
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    parcelId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      emailTypes: [
        { title: 'Plain Email', icon: 'mdi-email', color: 'indigo', type: 'plain' },
        {
          title: 'Upcoming Conservation Trust Visit',
          icon: 'mdi-calendar-clock',
          color: 'info',
          type: 'upcomingVisit'
        },
        {
          title: 'Conservation Trust Visit Success',
          icon: 'mdi-calendar-check',
          color: 'success',
          type: 'visitSuccess'
        }
      ],
      emailData: {
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        body: ``,
        values: null
      },
      edit: false,
      contact: null,
      parcel: null,
      street: null,
      nextDate: null,
      lastDate: null,
      templates: null
    };
  },
  mounted() {
    this.fetchData();
    this.fetchTemplates();
  },
  methods: {
    fetchData() {
      const c = new Contact();
      c.get(this.parcelId).then(data => {
        this.contact = data?.data;
      });
      const p = new Parcel({ id: this.parcelId });
      p.detail().then(data => {
        this.parcel = { ...data, address: getStreetAddress(data) };
        this.street = getStreetAddress(data);
      });
      const land = new Land();
      land.listActivity(this.parcelId).then(data => {
        const scheduled = (data || []).filter(({ date }) => {
          return moment(date).isAfter(moment());
        });
        if (scheduled && scheduled[0]) {
          this.nextDate = moment(scheduled[0]?.date).format('MMMM DD');
        }

        const lastDate = (data || []).filter(({ date }) => {
          return moment(date).isSameOrBefore(moment());
        });
        if (lastDate && lastDate[0]) {
          this.lastDate = moment(lastDate[0]?.date).format('MMMM DD');
        }
      });
    },
    fetchTemplates() {
      const cms = new CMS();
      cms.get({ type: 'html' }).then(data => {
        if (data.size) {
          const plainTemplate = { id: randomNumber(), subject: '', body: '', icon: 'mdi-email' };
          let templates = [];
          data.forEach(item => {
            const i = item.data();
            if (i.key.includes('CR_email')) {
              templates.push(i);
            }
          });
          templates = sort({
            items: templates,
            generator: item => {
              try {
                return Number(item.key.split('_').pop());
              } catch {
                return 999;
              }
            }
          });
          templates = templates.map(i => {
            const html = i.html.split('\n');
            const subject = html[0];
            html.splice(0, 1);
            const body = html.join('\n');
            const json = parseJson(i.json, {});
            return { subject, body, id: randomNumber(), ...json };
          });
          templates.splice(0, 0, plainTemplate);
          this.templates = templates;
        }
      });
    },
    send() {
      this.edit = false;
      let q = `mailto:${this.emailData.to}?`;
      ['cc', 'bcc', 'subject', 'body'].forEach(key => {
        q += `&${key}=${this.emailData[key]}`;
      });
      q = q.replace(/(?:\r\n|\r|\n)/g, '%0D%0A');
      window.open(q, '_blank').focus();
      this.$emit('success');
    },
    sendEmail(id) {
      const template = this.templates.filter(i => i.id === id)[0];
      const userEmail = this.$store?.state?.user?.email || '';
      const userProfile = this.$store.state?.user?.profile;
      const orgName = this.$store?.state?.loggedInAs?.name;
      const orgKey = this.$store?.state?.loggedInAs?.key;

      let bccEmails = this.$store.state.organizationSetting.bccEmails || '';
      if (Array.isArray(bccEmails)) {
        bccEmails = bccEmails.join(',');
      }

      const emailData = {
        to: this.contact.email,
        cc: userEmail,
        bcc: bccEmails,
        subject: template?.subject || '',
        body: template?.body || ''
      };
      this.emailData = emailData;

      const contactData = {
        ...this.contact
      };

      const address = this.contact.address;
      contactData.address = {};
      if (Array.isArray(address)) {
        address.forEach(i => {
          contactData.address[i.label] = i;
        });
      }

      const phone = this.contact.phone;
      contactData.phone = {};
      if (Array.isArray(phone)) {
        phone.forEach(i => {
          contactData.phone[i.label] = i.number;
        });
      }

      this.emailData.values = {
        contact: contactData,
        parcel: this.parcel,
        org: {
          name: orgName,
          key: orgKey
        },
        member: {
          firstName: userProfile.first_name,
          lastName: userProfile.last_name,
          fullName: `${userProfile.first_name || ''} ${userProfile.last_name || ''}`,
          email: this.$store.state.user.email
        },
        activity: {
          nextDate: this.nextDate,
          lastDate: this.lastDate
        }
      };

      if (!template?.body) {
        this.send();
        return;
      } else {
        this.emailData.body = Mustache.render(this.emailData.body, this.emailData.values);
        this.edit = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/design/index.scss';
.list-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.email-container {
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #e1eeff;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    border-color: #bedafd;
  }
  .email-content {
    .email-title {
      word-break: normal;
      font-size: 1rem;
    }
  }
}
</style>